<template>
  <div class="container">
    <page-title :title="`Bidbond #${details.number}`" />
    <div class="row row-sm">
      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <div id="bid-bond-template" v-html="bidbond"></div>
          </div>
        </div>
      </div>
			<div class="col-md-3" v-if="is_loaded">

        <a :href="bidbond_url" target="_blank" v-if="$can('bidbonds.download-preview')" class="btn btn-primary btn-block mb-3" style="color:white !important">Download this preview</a>
				<div class="card">
					<div class="card-body">
						<h4>Status</h4>
            <div :class="`alert alert-${details.status == 'processing' ? 'warning' : details.status == 'confirmed' ? 'success' : 'danger'}`">{{ details.status }}</div>
            <div v-if="details.status == 'confirmed'" class="mt-3">
              <a :href="details.document" target="_blank" class="btn btn-secondary btn-sm"><i class="lni-download mr-2"></i> Download</a>
            </div>
					</div>
				</div>
        <div class="card" v-if="$can('bidbonds.upload')">
          <div class="card-body">
            <h4>Upload Bidbond</h4>
            <form @submit.prevent="upload">
              <div class="form-group">
                <label>Select File</label>
                <input type="file" class="" ref="bidbond" id="select-file" required>
              </div>
              <div class="form-group text-right">
                <button class="btn btn-primary btn-sm"><i class="lni-upload mr-2"></i>Upload issued bidbond document</button>
              </div>
            </form>
          </div>
        </div>
			</div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      bidbond: "",
      is_loaded: false,
      details: {}
    };
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios
        .get(
          `/api/v1/dashboard/bidbonds/${this.$route.params.id}/view`
        )
        .then(response => {


          if(response.data == 0) {
            this.$loader.stop()
            this.$router.push({ name: 'bidbonds.payment' })
          } else {
      
            this.bidbond = response.data;
            this.fetchDetails()
            this.$loader.stop()
          }
        });
    },

    fetchDetails() {
      this.is_loaded = false
      this.$axios.get(`/api/v1/dashboard/bidbonds/${this.$route.params.id}`).then(response => {

        this.details = response.data
        this.is_loaded = true
      })
    },

    upload() {
      this.$loader.start()

      var data = new FormData();
      data.append("bidbond", this.$refs.bidbond.files[0]);

      this.$axios.post(`/api/v1/dashboard/bidbonds/${this.$route.params.id}/document`, data).then(() => {
        this.$loader.stop()
        this.fetchDetails()
      })
    }
  },

  computed: {
    bidbond_url() {
      return `${process.env.VUE_APP_API_URL}/requests/${this.$route.params.id}.pdf`
    }
  }
};
</script>